import { styled, css } from "styled-components";
import theme from "styled-theming";

import { Container as GridContainer } from "style/components/Page";
import { styleBodyLAlt, styleHeadingXL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    ${columnGap}
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding: ${theme("blockInBlock", {
        true: "0",
    })};
    padding-bottom: 4.375rem;

    ${theme("blockInBlock", {
        true: css`
            padding-bottom: 2.1875rem;
        `
    })}

    ${MQ.FROM_M} {
        display: grid;
        grid-auto-rows: auto;
        grid-template-columns: repeat(12, [col-start] 1fr);
        padding-bottom: 6.125rem;

        ${theme("blockInBlock", {
            true: css`
                padding-bottom: 3.5rem;
            `
        })}
    }

    ${MQ.FROM_L} {
        ${theme("blockInBlock", {
            true: css`
                grid-template-columns: repeat(7, [col-start] 1fr);
            `
        })}
    }

    ${MQ.FROM_XL} {
        padding-bottom: 8.75rem;
        ${theme("blockInBlock", {
            true: css`
                grid-template-columns: repeat(8, [col-start] 1fr);
                padding-bottom: 4.375rem;
            `
        })}
    }
`;

export const Heading = styled.h2`
    ${styleHeadingXL};
    grid-column: col-start 1 / span 4;
    margin-bottom: 2.625rem;
    margin-top: 0;

    ${theme("displayOption", {
        Right: css`
            grid-column: col-start 1 / span 12;
            text-align: right;
        `
    })};

    ${theme("headingRight", {
        true: css`
            text-align: right;
        `
    })};

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 12;
        grid-column: ${theme("blockInBlock", {
            true: "col-start 1 / span 7"
        })};
        ${theme("displayOption", {
            Right: css`
                grid-column: col-start 4 / span 12;
            `
        })}
        margin-bottom: 4.375rem;
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 1 / span 9;
        grid-column: ${theme("blockInBlock", {
            true: "col-start 1 / span 8"
        })};
        ${theme("displayOption", {
            Right: css`
                grid-column: col-start 4 / span 9;
            `
        })}
        margin-bottom: 6.125rem;
    }
`;

export const Introduction = styled.p`
    ${styleBodyLAlt};
    grid-column: col-start 1 / span 4;
    margin-bottom: 1.625rem;
    margin-top: 0;

    ${MQ.FROM_M} {
        grid-column: col-start 5 / span 8;
        ${theme("displayOption", {
            Right: css`
                grid-column: col-start 2 / span 8;
            `
        })}
    }

    ${MQ.FROM_L} {
        grid-column: col-start 5 / span 7;
        grid-column: ${theme("blockInBlock", {
            true: "col-start 2 / span 6"
        })};
        ${theme("displayOption", {
            Right: css`
                grid-column: col-start 2 / span 7;
            `
        })}
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 6 / span 6;
        grid-column: ${theme("blockInBlock", {
            true: "col-start 2 / span 6"
        })};
        ${theme("displayOption", {
            Right: css`
                grid-column: col-start 2 / span 6;
            `
        })}
    }
`;

export const Text = styled.div`
    grid-column: col-start 1 / span 4;

    ${MQ.FROM_M} {
        grid-column: col-start 5 / span 8;
        ${theme("displayOption", {
            Right: css`
                grid-column: col-start 2 / span 8;
            `
        })}
    }

    ${MQ.FROM_L} {
        grid-column: ${theme("blockInBlock", {
            true: "col-start 2 / span 6"
        })};
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 6 / span 7;
        grid-column: ${theme("blockInBlock", {
            true: "col-start 2 / span 5"
        })};
        ${theme("displayOption", {
            Right: css`
                grid-column: col-start 2 / span 7;
            `
        })}
    }
`;

export const ReadMore = styled.div`
    margin-bottom: 1.625rem;
    margin-top: 1.625rem;
    overflow: hidden;
    transition: all ease-in-out 0.2s;

    ${theme("readMore", {
        true: css`
            height: ${props => props.theme.readMoreHeight}px;
        `
    })}

    ${theme("readMore", {
        false: css`
            height: 14rem;
            mask-image: linear-gradient(to bottom, black 70%, transparent);
            mask-position: 0 100%;
            mask-size: 100% 200%;
        `
    })}
`;

export const ReadMoreButtonWrapper = styled.div`
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(4, [col-start] 1fr);
    text-align: center;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(8, [col-start] 1fr);
    }

    ${MQ.FROM_XL} {
        grid-template-columns: repeat(7, [col-start] 1fr);
    }
`

export const ReadMoreButtonContainer = styled.div`
    grid-column: 1 / span 4;

    ${MQ.FROM_M} {
        grid-column: 1 / span 8;
    }

    ${MQ.FROM_L} {
        grid-column: 1 / span 6;
        grid-column: ${theme("blockInBlock", {
            true: "1 / span 8"
        })}
    }

    ${MQ.FROM_XL} {
        grid-column: 1 / span 5;
        grid-column: ${theme("blockInBlock", {
            true: "1 / span 7"
        })}
    }
`
