import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import React, { ReactElement, useContext, useState, useRef, useEffect } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import Button from "components/Button/Button";
import Section from "components/Section";
import TextBlock from "components/TextBlock";
import useIsInEditMode from "hooks/useIsInEditMode";
import { addEditAttributes } from "utils/episerver";

import {
    Container,
    Introduction,
    Heading,
    Text,
    ReadMore,
    ReadMoreButtonWrapper,
    ReadMoreButtonContainer
} from "./EditorialSection.styled";
import EditorialSectionProps from "./EditorialSectionProps";

const EditorialSection = ({
    heading,
    introduction,
    mainBody,
    secondaryBody,
    identifier,
    anchorName,
    theme,
    contentTheme = "black",
    actionTheme,
    displayOption = "Left",
    l18n,
}: EditorialSectionProps): ReactElement => {
    const isInEditMode = useIsInEditMode();

    const [readMore, setReadMore] = useState(false);
    const [readMoreHeight, setReadMoreHeight] = useState(0);
    const readMoreButtonText = readMore ? l18n?.showLessText : l18n?.showMoreText;
    const contentRef = useRef<HTMLDivElement>(null);
    const sendDataToGTM = useGTMDispatch();

    useEffect(() => {
        if (contentRef.current) {
            setReadMoreHeight(contentRef.current.scrollHeight);
        }
    }, [contentRef.current?.scrollHeight]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        sendDataToGTM({
            event: "editorial_showmore",
            click_text: readMoreButtonText,
            editorialHeading: heading || "undefined",
        });
        setReadMore(!readMore); // eslint-disable-line
    };

    const themeContext = useContext(ThemeContext);
    if (!theme) theme = (themeContext && themeContext.theme) || "lightgray";

    return (
        <Section theme={theme} alignItems="center">
            <ThemeProvider
                theme={{
                    displayOption: displayOption,
                    readMore: readMore,
                    readMoreHeight: readMoreHeight,
                }}
            >
                <Container key={identifier} id={anchorName}>
                    {(isInEditMode || heading) && (
                        <Heading
                            {...addEditAttributes("Heading")}
                            dangerouslySetInnerHTML={{ __html: heading || "" }}
                        />
                    )}
                    {(isInEditMode || introduction) && (
                        <Introduction
                            {...addEditAttributes("Introduction")}
                            dangerouslySetInnerHTML={{
                                __html: introduction || "",
                            }}
                        />
                    )}
                    {(isInEditMode || mainBody) && (
                        <Text {...addEditAttributes("MainBody")}>
                            <TextBlock
                                content={mainBody}
                                areaName="mainBody"
                                theme={theme}
                                contentTheme={contentTheme}
                                actionTheme={actionTheme}
                                editorialGrid={true}
                            />
                        </Text>
                    )}
                    {(isInEditMode || (mainBody && secondaryBody)) && (
                        <Text {...addEditAttributes("SecondaryBody")}>
                            <ReadMore ref={contentRef}>
                                <TextBlock
                                    content={secondaryBody}
                                    areaName="secondaryBody"
                                    theme={theme}
                                    contentTheme={contentTheme}
                                    actionTheme={actionTheme}
                                    editorialGrid={true}
                                />
                            </ReadMore>
                            <ReadMoreButtonWrapper>
                                <ReadMoreButtonContainer>
                                    <Button
                                        onClick={(event) => handleClick(event)}
                                        aria-expanded={readMore}
                                        icon={
                                            readMore
                                                ? "bigArrowUp28"
                                                : "bigArrowDown28"
                                        }
                                        actionTheme={actionTheme}
                                        isExpand={true}
                                        variant="simple"
                                    >
                                        {readMoreButtonText}
                                    </Button>
                                </ReadMoreButtonContainer>
                            </ReadMoreButtonWrapper>
                        </Text>
                    )}
                </Container>
            </ThemeProvider>
        </Section>
    );
};

export default React.memo(EditorialSection);
